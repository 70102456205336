// components
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/nexgen-sportswear/nexgen-sportswear-header-image.jpg";
import Globe from "../images/icon/countriesIcon.svg";
import Handshake from "../images/icon/handshake-icon.svg";
import CheckIcon from "../images/icon/check.svg";
import Sizeicon from "../images/limton/size-green.svg";
import Facts from "../images/confi/location.svg";
import Foursource from "../images/confi/foursource.svg";
import Apparel from "../images/confi/apparel.svg";
import Profile from "../images/confi/profile.svg";
import Size from "../images/confi/size.svg";
import ApparelManufacturer from "../images/confi/apparelManufacturer.svg";
import Banner from "../images/confi/banner.svg";
import fawad from "../images/nexgen-sportswear/fawad-ahmad-nexgen-sportswear.jpg";


export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("nexgen-sportswear.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("nexgen-sportswear.subTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("nexgen-sportswear.imgAlt"),
      },
    },
    topIcons: [
      {
        title: {
          text: t("nexgen-sportswear.roiValue"),
          isNum: true,
          textBefore: "+"
        },
        subtitle: {
          text: t("nexgen-sportswear.roiSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Globe,
          alt: t("nexgen-sportswear.roiAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.recruitingValue"),
          textBefore: "+",
          isNum: true,
        },
        subtitle: {
          text: t("nexgen-sportswear.recruitingSubtitle"),
          colorClass: "colorDark",
        },
        
        icon: {
          src: Sizeicon,
          alt: t("nexgen-sportswear.newMarketsAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.newMarketsValue"),
        },
        subtitle: {
          text: t("nexgen-sportswear.newMarketsSubtitle"),
          colorClass: "colorDark text-center",
        },
        icon: {
          src: Handshake,
          alt: t("nexgen-sportswear.recruitingAlt"),
        },
      },
    ],
    sideIcons1: [
      {
        title: {
          text: t("nexgen-sportswear.locationTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.locationSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Facts,
          alt: t("nexgen-sportswear.locationAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.productTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.productSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Apparel,
          alt: t("nexgen-sportswear.productAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.memberTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.memberSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: ApparelManufacturer,
          alt: t("nexgen-sportswear.memberAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.membershipTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.membershipSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Profile,
          alt: t("nexgen-sportswear.membershipAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.sizeTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.sizeSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Size,
          alt: t("nexgen-sportswear.sizeAlt"),
        },
      },
      {
        title: {
          text: t("nexgen-sportswear.sinceTitle"),
        },
        subtitle: {
          text: t("nexgen-sportswear.sinceSubtitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: Foursource,
          alt: t("nexgen-sportswear.sinceAlt"),
        },
      },
    ],
    sideIcons2: [
      {
        title: {
          text: t("nexgen-sportswear.challengesSquareTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("nexgen-sportswear.challengesSquareSubtitle"),
          colorClass: "colorDark",
          textSize: "font17  pt-10"
        },
        icon: {
          src: '',
          alt: t("nexgen-sportswear.newMarketsAlt"),
        },
      },
    ],
    sideIcons3: [
      {
        title: {
          text: t("nexgen-sportswear.favoriteToolsTitle"),
          textSize: "font24 lineHeight1_3 ",
        },
        subtitle: {
          text: t("nexgen-sportswear.favoriteToolsSubtitle"),
          colorClass: "colorDark",
          textSize: " font17 pt-20"
        },
        icon: {
          src: '',
          alt: t("nexgen-sportswear.favoriteToolsAlt"),
        },
        banner: true,
        bannerSrc: Banner,
        bannerAlt: "Banner"
      },
    ],
    fawad: [
      {
        title: {
          text: t("nexgen-sportswear.fawadName"),
        },
        subtitle: {
          text: t("nexgen-sportswear.fawadTitle"),
          colorClass: "colorDarkLight",
        },
        icon: {
          src: fawad,
          alt: t("nexgen-sportswear.newMarketsAlt"),
        },
      },
    ],
    aboutInfo: {
      title: {
        text: t("nexgen-sportswear.aboutTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      subtitle: {
        text: t("nexgen-sportswear.aboutSubTitle"),
        colorClass: "colorDark",
      },
      text: {
        text: t("nexgen-sportswear.aboutText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
      subtitleFirst: true,
    },
    challengesInfo: {
      title: {
        text: t("nexgen-sportswear.challengesTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("nexgen-sportswear.challengesText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    findingInfo: {
      title: {
        text: t("nexgen-sportswear.findingTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("nexgen-sportswear.findingText"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    resultsInfo: {
      title: {
        text: t("nexgen-sportswear.resultsTitle"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    bullet: {
      icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt") },
      separator: false,
      item: {
        text: [
          t("nexgen-sportswear.bullet1"),
          t("nexgen-sportswear.bullet2"),
          t("nexgen-sportswear.bullet3"),
          t("nexgen-sportswear.bullet4"),
        ],
        colorClass: "colorDark",
      },
      customPBText: true,
    },
    cta: {
      text: t("nexgen-sportswear.cta1"),
      url: "https://foursource.com/apparel-manufacturers/",
      type: "whiteColor",
    },
    cta2: {
      text: t("nexgen-sportswear.cta2"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    squareText: t("nexgen-sportswear.challengesSquareSubtitle2"),
    favoriteToolsExtra: t("nexgen-sportswear.favoriteToolsExtra"),
    profileText: t("nexgen-sportswear.profileText"),
    testimonyName: t("nexgen-sportswear.fawadName"),
    testimonyTitle: t("nexgen-sportswear.fawadTitle"),
    anchorText: t("nexgen-sportswear.anchorText")
  };
};
